import React, { useState } from "react";
import Banner from "../components/Banner";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { VscCombine } from "react-icons/vsc";
import features_img from "../images/features_img.png";
import process_line from "../images/process_line.svg";
import bg_shape from "../images/service_grid_bg.png";
import Number_Statistics from "../components/Number_Statistics";
import { AiOutlineFileDone } from "react-icons/ai";
import { VscOpenPreview } from "react-icons/vsc";
import { IoMdDoneAll } from "react-icons/io";
import LoanCalculator from "../components/LoanCalculator";
import ChatButton from "../components/ChatButton";
import underlay_img from "../images/get_started_underlay.jpg";
import overlay_img from "../images/get_started_overlay.jpg";
import { FaApple } from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import { BiSolidCustomize } from "react-icons/bi";
import { SiFsecure } from "react-icons/si";
import { FaStrava } from "react-icons/fa";
import { FaHandsClapping } from "react-icons/fa6";
import { FaDroplet } from "react-icons/fa6";
import { SiWebmoney } from "react-icons/si";
import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineDone,
} from "react-icons/md";
import step_2_img from "../images/step_2.png";
import step_3_img from "../images/step_3.png";
import image_111 from "../images/image_111.jpg"
import { aboutParagraph, aboutParagraphHidden, advantages, debtConsolidation, instantloan, secureFuture, shortloan, weWork } from "../content/text";
import mobilesl from "../images/mobilesl.png"
import mobiles2 from "../images/mobiles2.JPG"
const Home = () => {
  const [step, setStep] = useState(1);
  return (
    <>
      <div className="page_wrapper">
        <Banner />

        <div className="about_us ">
          <div className="about_us_content">
            <div className="title_section">
              <p className="sub_title flex flex-center">
                <span>
                  <BsFillGrid1X2Fill
                    className="mr10"
                    style={{ marginBottom: "-2px" }}
                  />
                </span>
                <span>About SuryaLoan</span>
              </p>
              <h2 className="mt20">
                SuryaLoan: Your Reliable <span>Financial Partner</span>
              </h2>
            </div>
            <div className="about_para">
              <p>
                {aboutParagraph}
                <span className="hide_content">
                  {aboutParagraphHidden}
                </span>
                <Link to="/about">...Read More</Link>
              </p>
            </div>
          </div>
          <LoanCalculator />
        </div>

        <div className="services_section">
          <div className="flex flex-center info_content space-between">
            <div className="title_section">
              <p className="sub_title flex flex-center">
                <span>
                  <BsFillGrid1X2Fill
                    className="mr10"
                    style={{ marginBottom: "-2px" }}
                  />
                </span>
                <span>What We do</span>
              </p>

              <h2 className="mt20">
               Customized Financial Solutions for
                <span> your needs</span>
              </h2>
            </div>
          </div>
          <div className="services_card_container">
            <div className="services_card">
              <div className="services_icon_container flex flex-center justify-center">
                <GiPayMoney className="service_icon" />
              </div>
              <div className="title full-width text-center mb10">
                <h4>Instant Loan</h4>
              </div>
              <div className="card_content">
                <p>
                  {instantloan}
                </p>

                <div className="btn_container mt30">
                  <Link to="/instantloan">Read More</Link>
                </div>
              </div>

              <div className="bg_shape">
                <img src={bg_shape} alt="" />
              </div>
            </div>
            <div className="services_card">
              <div className="services_icon_container flex flex-center justify-center">
                <GiTakeMyMoney className="service_icon" />
              </div>
              <div className="title full-width text-center mb10">
                <h4>Short Loans</h4>
              </div>
              <div className="card_content">
                <p>
                 {shortloan}
                </p>

                <div className="btn_container mt30">
                  <Link to="/shortloan">Read More</Link>
                </div>
              </div>

              <div className="bg_shape">
                <img src={bg_shape} alt="" />
              </div>
            </div>
            <div className="services_card">
              <div className="services_icon_container flex flex-center justify-center">
                <VscCombine className="service_icon" />
              </div>
              <div className="title full-width text-center mb10">
                <h4>Debt Consolidation</h4>
              </div>
              <div className="card_content">
                <p>
                  {debtConsolidation}
                </p>

                <div className="btn_container mt30">
                  <Link to="/services">Read More</Link>
                </div>
              </div>

              <div className="bg_shape">
                <img src={bg_shape} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="features_section">
          <div className="features_section_content ">
            <div>
              <div className="title_section">
                <p className="sub_title flex flex-center">
                  <span>
                    <BsFillGrid1X2Fill
                      className="mr10"
                      style={{ marginBottom: "-2px" }}
                    />
                  </span>
                  <span>Why Choose Us</span>
                </p>

                <h2 className="mt20">
                Offering personal loans to {" "}
                  <span>help secure your future success.</span>
                </h2>
              </div>

              <div className="features_section_content_description mt20">
                <p>
                 {secureFuture}
                </p>
              </div>
            </div>
            <div className="features_image">
              <img src={features_img} alt="" />
            </div>
          </div>
          <Number_Statistics />

          <div className="general_working">
            <div className="title_section">
              <p className="sub_title flex flex-center justify-center">
                <span>
                  <BsFillGrid1X2Fill
                    className="mr10"
                    style={{ marginBottom: "-2px" }}
                  />
                </span>
                <span>Why Choose Us</span>
              </p>

              <h2 className="mt20 full-width text-center">
                How We <span>Work ?</span>
              </h2>
            </div>
            <div className="process_card_area mt50">
              <div className="process_line">
                <img src={process_line} alt="" />
              </div>
              <div className="process_row">
                {
                  weWork.map((item, index)=>{
                    return (
                      <div className="process_card" key={index}>
                  <div className="process_card_icon flex flex-center justify-center mb20">
                    {item.icon}
                  </div>
                  <div className="process_card_number">{index+1}</div>
                  <h2 className="mb10">{item.title}</h2>
                  <p className="full-width text-center">
                    {item.description}
                  </p>
                </div>
                    )
                  })
                }
              </div>
            </div>
          </div>

          <div className="advantages_wrapper">
            <div className="title_section">
              <h2 className="mt20 full-width text-center">
                SuryaLoan's <span>Advantages</span>
              </h2>
              <p className="mt5 text-center">
                Here's how we make your financial journey smooth
              </p>
            </div>
            <div className="advantages_section">
              <div className="container">
                <div className="advantages_section_row">
                  {advantages.map((item, index)=>{
                    return (
                      <div className="advantage_section_card" key={index}>
                    <div className="advantage_section_icon_container">
                      {item.icon}
                    </div>
                    <div>
                      <h3>{item.title}</h3>
                      <p>
                        {item.description}
                      </p>
                    </div>
                  </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="get_started_section">
          <div className="title_section ">
            <h2 className="mt20 full-width text-center">
              Get Started <span>Instantly</span>
            </h2>
            <p className="full-width text-center mt5">
              Download the SuryaLoan app and get moving
            </p>
          </div>
          <div className="get_started_row">
            {step === 1 ? (
              <>
                <div className="image_section">
                  <img src={mobilesl} alt="" className="underlay_img" />
                  {/* <img src={overlay_img} className="overlay_img" /> */}
                </div>
              </>
            ) : step === 2 ? (
              <>
                <div className="image_section">
                  <img src={mobiles2} alt="" />
                </div>
              </>
            ) : step === 3 ? (
              <>
                <div className="image_section">
                  <img src={step_3_img} alt="" />
                </div>
              </>
            ) : (
              ""
            )}
            <div className="steps_section">
              <h3 className="mb50">How does this App Work ?</h3>
              <div
                className={
                  step === 1 ? "step_item flex step_active" : "step_item flex"
                }
              >
                <div
                  className={
                    step === 1
                      ? "step_icon_container icon_active"
                      : "step_icon_container"
                  }
                  onClick={(e) => setStep(1)}
                >
                  <MdOutlineFileDownload className="step_icon" />
                </div>
                <div className="step_content">
                  <h4 className="content_title">Download the App and Apply</h4>
                  <p>
                    Head over to apply for loan section and fill your basic
                    details to get started
                  </p>
                </div>
              </div>
              <div
                className={
                  step === 2 ? "step_item flex step_active" : "step_item flex"
                }
              >
                <div
                  className={
                    step === 2
                      ? "step_icon_container icon_active"
                      : "step_icon_container"
                  }
                  onClick={(e) => setStep(2)}
                >
                  <MdOutlineFileUpload className="step_icon" />
                </div>
                <div className="step_content">
                  <h4 className="content_title">Upload Documents</h4>
                  <p>
                    Upload KYC Documents (PAN and Aadhar Card) income proof &
                    wait for verification
                  </p>
                </div>
              </div>
              <div
                className={
                  step === 3
                    ? "step_item flex step_active last_item"
                    : "step_item flex last_item"
                }
              >
                <div
                  className={
                    step === 3
                      ? "step_icon_container icon_active"
                      : "step_icon_container"
                  }
                  onClick={(e) => setStep(3)}
                >
                  <MdOutlineDone className="step_icon" />
                </div>
                <div className="step_content">
                  <h4 className="content_title">Get Approved Instantly</h4>
                  <p>
                    Post verification the disbursal process is Initiated
                    instantly
                  </p>
                </div>
              </div>
              <div className="app_download_button_container ml70">
                <p className="mb10">Download the app now</p>
                <div className="flex flex-center button_wrapper">
                  <Link to="">
                    <FaApple className="app_download_icon" />
                  </Link>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.SuryaLoan.SuryaLoan"
                    target="_blank"
                  >
                    <DiAndroid className="app_download_icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};

export default Home;
