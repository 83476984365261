import React from "react";
import "../css/Common.css";
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { VscCombine } from "react-icons/vsc";
import { FaRupeeSign } from "react-icons/fa";
import { FaCodeCommit } from "react-icons/fa6";
import { TbAdjustmentsCog } from "react-icons/tb";
import { Link } from "react-router-dom";
import ChatButton from "../components/ChatButton";
import { services } from "../content/text";

const Services = () => {
  return (
    <>
      <div className="page_wrapper">
        <div className="page_banner_wrapper">
          <div className="page_banner_wrapper_overlay">
            <h2>Our Services</h2>
          </div>
        </div>

        <div className="services_page_section"  data-aos="zoom-in">
          <div className="services_row">
            {services.map((item, index)=>{
              return (
                <Link to={`${item.link}`} key={index}>
              <div className="services_item">
                <div className="services_icon_container">
                  {item.icon}
                </div>
                <div className="services_content">
                  <div className="services_title">
                    <h4>{item.title}</h4>
                  </div>
                  <p>
                    {item.description}
                  </p>
                </div>
              </div>
            </Link>
              )
            })}
            {/* <Link to="/instantloan">
              <div className="services_item">
                <div className="services_icon_container">
                  <GiPayMoney className="services_icon" />
                </div>
                <div className="services_content">
                  <div className="services_title">
                    <h4>Instant Loan</h4>
                  </div>
                  <p>
                    We offer instant loans to help you tackle unexpected
                    financial needs swiftly and efficiently. 
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/shortloan">
              <div className="services_item">
                <div className="services_icon_container">
                  <GiTakeMyMoney className="services_icon" />
                </div>
                <div className="services_content">
                  <div className="services_title">
                    <h4>Short Loan</h4>
                  </div>
                  <p>
                    Discover the convenience of short loans at SuryaLoan,
                    designed to meet your immediate financial needs with ease.
                  
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/debtconsolidation">
              <div className="services_item">
                <div className="services_icon_container">
                  <VscCombine className="services_icon" />
                </div>
                <div className="services_content">
                  <div className="services_title">
                    <h4>Debt Consolidation</h4>
                  </div>
                  <p>
                    Take control of your finances with our debt consolidation
                    services. Consolidate multiple debts into one manageable
                    payment 
                  </p>
                </div>
              </div>
            </Link> */}
          </div>
          {/* <div className="services_row">
            <Link to="/flexibility">
              <div className="services_item">
                <div className="services_icon_container">
                  <TbAdjustmentsCog className="services_icon" />
                </div>
                <div className="services_content">
                  <div className="services_title">
                    <h4>Flexibility</h4>
                  </div>
                  <p>
                    Short-term loans often come with more flexible eligibility
                    criteria and faster approval processes compared.
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/minimalcommitment">
              <div className="services_item">
                <div className="services_icon_container">
                  <FaCodeCommit className="services_icon" />
                </div>
                <div className="services_content">
                  <div className="services_title">
                    <h4>Minimal Commitment</h4>
                  </div>
                  <p>
                  Short-term loans typically have shorter repayment periods,
                  ranging from a few weeks to a few months.
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/costeffective">
              <div className="services_item">
                <div className="services_icon_container">
                  <FaRupeeSign className="services_icon" />
                </div>
                <div className="services_content">
                  <div className="services_title">
                    <h4>Cost Effective</h4>
                  </div>
                  <p>
                  While short-term loans may have higher interest rates compared
                  to long-term loans, the total cost of borrowing.
                  </p>
                </div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
      <ChatButton/>
    </>
  );
};

export default Services;
